import React, { useState } from 'react';
import './ContactSectionForm.css';
import { collection, addDoc } from 'firebase/firestore';
import db from '../config/firebaseConfig';
import FormSuccessModal from './FormSuccessModal'; // Import your FormSuccessModal component



const ContactSectionForm = () => {

    const currentDate = new Date().toISOString().substring(0, 10);
    const currentTime = new Date().toTimeString().substring(0, 5);

    const [formData, setFormData] = useState({ name: '', email: '', message: '', date: currentDate, time: currentTime });
    const [successMessage, setSuccessMessage] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await addDoc(collection(db, "contacts"), {
                name: formData.name,
                email: formData.email,
                message: formData.message,
                date: formData.date,
                time: formData.time,
            });

            // Show success message
            setSuccessMessage('Your message has been sent successfully!');
            setShowSuccessModal(true);
            // Optionally, remove the success message after a few seconds
            setTimeout(() => {
                // setSuccessMessage('');
                setShowSuccessModal(false);
            }, 5000);

        } catch (error) {
            console.error("Error adding document: ", error);
            // Handle error (e.g., show error message)
        }

        // Clear the form
        setFormData({ name: '', email: '', message: '', date: currentDate, time: currentTime });
    };



    return (
        <div className="contact-form">
            <form onSubmit={handleSubmit}>
                <label htmlFor="name" className="form-label">Name *</label>
                <input
                    className='form-input'
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />

                <label htmlFor="email" className="form-label">Email *</label>
                <input
                    className='form-input'
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter Email Address"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />

                <label htmlFor="organization" className="form-label">Organization</label>
                <input
                    className='form-input'
                    type="text"
                    id="organization"
                    name="organization"
                    placeholder="Enter Organization"
                    value={formData.organization || ''} // Update accordingly if you have an organization field in your state
                    onChange={handleChange} // Ensure you handle this field in your handleChange
                />

                <label htmlFor="message" className="form-label">Message *</label>
                <textarea
                    className='form-textarea'
                    id="message"
                    name="message"
                    placeholder="Enter Message"
                    rows="4"
                    value={formData.message}
                    onChange={handleChange}
                    required
                />

                <button className="form-button" type="submit">Submit</button>
            </form>
            {showSuccessModal && <FormSuccessModal isOpen={showSuccessModal} onClose={() => setShowSuccessModal(false)} />}
        </div>
    );
};

export default ContactSectionForm;