import React from 'react';
import Question from './FAQuestion';
import './FAQSection.css';

function FAQSection() {
    // Add your questions and answers here
    const faqData = [
        { question: "What is Nuntium AI?", answer: "Nuntium AI is an enterprise software tool that enables high volume, multi-dimensional research into Emerging Markets." },
        { question: "How does Nuntium AI work?", answer: "Nuntium AI uses proprietary vector embeddings to identify patterns in large volumes of unstructured text and metadata, allowing our model to synthesize common and relevant themes." },
        { question: "What are the benefits of using Nuntium AI?", answer: "Using Nuntium AI saves time and effort by automating the research and analysis process. It helps gain insight into the convoluted, complicated world of Emerging Markets." },
        { question: "Is Nuntium AI easy to use?", answer: "Nuntium AI's user interface is designed to be user-friendly and intuitive. Nuntium has been developed to be easier to work with than most human analysts or other junior resources." }
    ];
    return (
        <div className="faq-container">
            <h2 className="faq-heading">
                Frequently Asked Questions
            </h2>
            <br />
            <div className="faq-content-container">
                {faqData.map(faq => (
                    <Question key={faq.question} question={faq.question} answer={faq.answer} />
                ))}
            </div>
        </div>
    );
}

export default FAQSection;
