import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import Home from './views/home'
import NotFound from './views/not-found'
import CaseStudyPage from './views/CaseStudyPage'; // Import your new component
import AboutPage from './views/AboutPage'
import ContactPage from './views/ContactPage'
import PolicyPage from './views/PolicyPage'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={Home} exact path="/" />
        <Route component={CaseStudyPage} path="/case_study" />
        <Route component={AboutPage} path="/about" />
        <Route component={ContactPage} path="/contact" />
        <Route component={PolicyPage} path="/policy" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
