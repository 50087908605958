// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
    apiKey: "AIzaSyBGKi8v0Ytz_Y06DZcMOq6F-vkBU3MlF_M",
    authDomain: "nuntiumai-76f1b.firebaseapp.com",
    projectId: "nuntiumai-76f1b",
    storageBucket: "nuntiumai-76f1b.appspot.com",
    messagingSenderId: "834943710304",
    appId: "1:834943710304:web:536ceaf4e76b52e52ebf05",
    measurementId: "G-CST1QKB3YL"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);



const db = getFirestore(app);

export default db;