import React from 'react';
import Header from '../landing_page_components/HeaderSection';
import Title from '../casestudy_page_components/Title';
import FooterSection from '../landing_page_components/FooterSection';
import OurStory from '../about_page_components/OurStory';
import OurMission from '../about_page_components/OurMission';
import OurTeam from '../about_page_components/OurTeam';
import PartnersCarousel from '../about_page_components/PartnersCarousel';

function AboutPage() {
    return (
        <div>
            <Header />
            <Title title="About Us" subheading="" />
            <OurStory />
            <OurMission />
            <OurTeam />
            {/* <PartnersCarousel /> */}
            <FooterSection />
        </div>
    )
}

export default AboutPage
