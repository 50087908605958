import React from 'react';
import './DetailsSectionContent.css';

const DetailsSectionContent = () => {
    return (
        <div className="details-info-container">
            <div className="detailsTitle">WHAT WE DO</div>
            <div className="mainTitle">Get Better, Faster,
                and Cheaper Analysis</div>
            <div className="description">Nuntium AI produces long-form research reports by synthesizing publicly available information with private data repositories. Work that traditionally took human analysts hours or days can now be fully automated with just a click of a button.</div>

        </div>
    );
};

export default DetailsSectionContent;