// Title.js
import './Title.css';
import React from 'react';

function Title({ title, subheading }) {
    return (
        <div className='title-container'>
            <div className='title-heading'>{title}</div>
            <br />
            <div className='title-subheading'>{subheading}</div>
        </div>
    )
}

export default Title