import React, { useState } from 'react';
import './FAQuestion.css';

import plusIcon from '../assets/Plus.png';
import minusIcon from '../assets/Minus.png';

function Question({ question, answer }) {
    const [isActive, setIsActive] = useState(false);


    const toggleIsActive = () => {
        setIsActive(!isActive);
    };

    return (
        <div className={`question-container ${isActive ? 'active' : ''}`} onClick={() => setIsActive(!isActive)}>
            <h3 className="question-title">
                {question}
                <img className="faq-toggle" src={isActive ? minusIcon : plusIcon} alt={isActive ? 'Collapse' : 'Expand'} />

            </h3>
            <div className="question-answer">{isActive && answer}</div>
        </div>
    );
}

export default Question;