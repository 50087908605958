// DetailsSection.js
import React from 'react';
import './DetailsSection.css';
import DetailsSectionContent from './DetailsSectionContent.js'
import DetailsSectionGraphic from './DetailsSectionGraphic.js'

function DetailsSection() {
    return (
        <section className="details-container">
            <DetailsSectionContent />
            <DetailsSectionGraphic />
        </section>
    );
}

export default DetailsSection;
