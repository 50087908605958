import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './HeaderSection.css';

const Header = () => {
    const [isNavVisible, setIsNavVisible] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setIsNavVisible(false);
            } else {
                setIsNavVisible(true);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="header">
            <div className="logo-container">
                <Link to="/" className="logo">nuntium</Link><span className="logo-accent">ai</span>
            </div>
            <button className="menu-toggle" onClick={() => setIsNavVisible(!isNavVisible)}>
                {isNavVisible ? '×' : '☰'}
            </button>
            {isNavVisible && (
                <div className={`nav-container ${isNavVisible ? 'show' : ''}`}>
                    <div className="nav-items-center">
                        <Link to="/" className="nav-item">Home</Link>
                        <Link to="/about" className="nav-item">About</Link>
                        <Link to="/case_study" className="nav-item">Case Studies</Link>
                        <Link to="/contact" className="nav-item">Contact</Link>
                    </div>
                    <a href="http://demo1.nuntiumai.com" target="_blank" rel="noopener noreferrer" className="nav-button">Try Demo</a>                </div>
            )}
        </div>
    );
};

export default Header;