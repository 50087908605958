import React from 'react';
import './DetailsSectionGraphic.css';
import searchImg from '../assets/Details1.png';
import filesImg from '../assets/Details2.png';
import timeImg from '../assets/Details3.png';

function DetailsSectionGraphic() {
    return (
        <div className="detail-image-container">
            <img className="detail-image" src={searchImg} alt="Search" />
            <img className="detail-image" src={filesImg} alt="Files" />
            <img className="detail-image" src={timeImg} alt="Time" />
        </div>
    );
}
export default DetailsSectionGraphic;
