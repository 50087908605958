import React from 'react';
import FeatureCard from './feature-card';
import './FeaturesSection.css';

function FeaturesSection() {
    return (
        <section id='features'>
            <div className="features">
                <div className="feature-description">
                    <span className="sectionTitle">
                        <span>FEATURES</span>
                        <br></br>
                        <br></br>
                    </span>
                    <h2 className="features-heading ">
                        Optimized for Efficient Research and Analysis
                    </h2>
                    <span className="features-sub-heading">
                        Discover how Nuntium AI can streamline your research and
                        analysis process.
                    </span>
                </div>

                <div className="feature-card-container">
                    <FeatureCard
                        Heading="Automated Research"
                        SubHeading="Nuntium AI automates the research process, saving you and your team time and effort."
                        IconNumber={1}
                    ></FeatureCard>
                    <FeatureCard
                        Heading="Data Analysis"
                        SubHeading="Present proprietary and public data in the form of easily digestable narrative themes."
                        IconNumber={2}
                    ></FeatureCard>
                    <FeatureCard
                        Heading="Information Accuracy"
                        SubHeading="Ensure that results link to verifiable sources, checking against false conclusions."
                        IconNumber={3}
                    ></FeatureCard>
                    <FeatureCard
                        Heading="Real-time Updates"
                        SubHeading="Stay up-to-date with real-time updates, ensuring your analysis reports remain relevant."
                        IconNumber={4}
                    ></FeatureCard>
                </div>

            </div>
        </section>
    );
}

export default FeaturesSection;