import React from 'react';
import './OurTeam.css'; // Assuming you will create a CSS file for styles
import TeamCard from './TeamCard';
import raffay_pic from '../assets/Raffay.jpeg';
import adi_pic from '../assets/Adi.jpg';


const OurTeam = () => {
    return (
        <div className="ourTeam">
            <div className="headingRow">
                Our Team
            </div>
            <br />
            <br />
            <div className="cardRow">
                <TeamCard
                    name="Adi Ram"
                    description="Testing if this description works"
                    image={adi_pic}
                    linedInUrl="https://www.linkedin.com/in/aditya-ram-226557138/"
                    emailAddress="foundingteam@nuntiumai.com"
                />
                <TeamCard
                    name="Raffay Rana"
                    description="Testing if this description works"
                    image={raffay_pic}
                    linedInUrl="https://www.linkedin.com/in/raffay-rana/"
                    emailAddress="foundingteam@nuntiumai.com"
                /></div>
        </div>
    );
};

export default OurTeam;
