import './OurStory.css';

const OurStory = () => {
    return (
        <div className="our-story">
            <div className="our-story-shadow-box">
                <div className="our-story-content ">
                    <div className="our-story-content-col">
                        <div className="our-story-title">Our Story</div>
                        <div className="our-story-description">Raffay and Adi are consultants who were developing AI tools to make their own jobs easier. One day, over a cup of coffee, they began discussing how big data could change the way investment and policy decisions were made emerging markets, especially in their home countries. That focus would eventually evolve into the Nuntium of today - a tool designed to make EM research more quantitative, more trustworthy, and more useful for users all around the globe.</div>
                    </div>
                    <div className="our-story-video-col">
                        <iframe
                            className="youtube-embed"
                            src="https://www.youtube.com/embed/Ng5ShSsS1Uo?si=strxKulbNkyb48JI"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>

                </div>
            </div>
        </div>

    );
}

export default OurStory;
