import React from 'react';
import Header from '../landing_page_components/HeaderSection';
import Title from '../casestudy_page_components/Title';
import CaseStudies from '../casestudy_page_components/CaseStudies';
import FooterSection from '../landing_page_components/FooterSection';

function CaseStudyPage() {
    return (
        <div>
            <Header />
            <Title title="Case Studies" subheading="Learn more about how Nuntium AI is your solution" />
            <CaseStudies />
            <FooterSection />
        </div>
    )
}

export default CaseStudyPage
