import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './BannerSection.css'; // Ensure you update your CSS file with the styles provided later

const BannerSection = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };
    return (
        <section className="banner-container">
            <div className="banner-info-container">
                <div className="bannerTitle">HOW DOES IT WORK</div>
                <div className="mainTitle">Unlock the Power of Automation</div>
                <div className="description">Discover how Nuntium AI can save you time and effort by automating your research and analysis tasks.</div>
                <div className="banner-button-container">
                    <Link onClick={scrollToTop} to="/case_study" className="banner-button-text">View Our Case Studies</Link>
                </div>
            </div>

            <div className="banner-video-container">
                <iframe
                    className="youtube-embed"
                    src="https://www.youtube.com/embed/ou5VmSfrKiY?si=Tad5B0krkxneKaRd"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>

        </section >
    );
};

export default BannerSection;