import React from 'react';
import { Helmet } from 'react-helmet'
import './home.css'
import Header from '../landing_page_components/HeaderSection';
import HeroSection from '../landing_page_components/HeroSection';
import DetailsSection from '../landing_page_components/DetailsSection';
import FeaturesSection from '../landing_page_components/FeaturesSection';
import BannerSection from '../landing_page_components/BannerSection';
import FAQSection from '../landing_page_components/FAQSection';
import Footer from '../landing_page_components/FooterSection';
import ContactSection from '../landing_page_components/ContactSection';

function Home() {
  return (
    // <div className="home-container">
    //   <Helmet>
    //     <title>NuntiumAI</title>
    //     <meta property="og:title" content="NuntiumAI" />
    //     <meta name="description" content="Nuntium AI is a research and analysis tool that automates the entire process, from data collection to knowledge base creation and future research suggestions. It can analyze massive datasets of publicly available news and private data repositories to identify key events, trends, and insights. Nuntium is perfect for businesses that need to stay ahead of the curve on industry trends, competitor analysis, and market research." />
    //   </Helmet>
    //   <Header />
    //   <HeroSection />
    //   <DetailsSection />
    //   <FeaturesSection />
    //   <BannerSection />
    //   <FAQSection />
    //   <ContactSection />
    //   <Footer />
    // </div>
    <div>
      <Header />
      <HeroSection />
      <DetailsSection />
      <FeaturesSection />
      <BannerSection />
      <FAQSection />
      <ContactSection />
      <Footer />
    </div>
  )
}

export default Home
