import React from 'react';
import Header from '../landing_page_components/HeaderSection';
import Title from '../casestudy_page_components/Title';
import FooterSection from '../landing_page_components/FooterSection';
import ContactPageSection from '../contact_us_page/ContactPageSection';

function ContactPage() {
    return (
        <div>
            <Header />
            <Title title="Contact Us" subheading="" />
            <ContactPageSection />
            <FooterSection />
        </div>
    )
}

export default ContactPage
