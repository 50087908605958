import React from 'react';
import './FormSuccessModal.css';
import form_success_img from '../assets/FormSuccess.png';


const FormSuccessModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-container" onClick={e => e.stopPropagation()}>

                <div className="modal-content">
                    <div className="modal-checkmark">
                        <img src={form_success_img} alt="Form Success" />
                    </div>

                </div>
                <div className="modal-header">
                    <h2 className='modal-heading'>Thank you For Contacting Us</h2>
                    <br />
                    <p className='modal-subheading'>We will get back to you within 48 hours</p>
                </div>
                <div className="modal-actions">
                    <button className="modal-close-btn" onClick={onClose}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FormSuccessModal;
