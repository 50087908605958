import React, { useState, useEffect } from 'react'
import DataIcon from '../assets/data-icon.svg';
import ResearchIcon from '../assets/research-icon.svg';
import HallucinationIcon from '../assets/hallucination-icon.svg';
import UpdatesIcon from '../assets/updates-icon.svg';
import './feature-card.css'

const FeatureCard = (props) => {
  var [Icon, setIcon] = useState(ResearchIcon); // default icon

  useEffect(() => {
    switch (props.IconNumber) {
      case 1:
        setIcon(ResearchIcon);
        break;
      case 2:
        setIcon(DataIcon);
        break;
      case 3:
        setIcon(HallucinationIcon);
        break;
      case 4:
        setIcon(UpdatesIcon);
        break;
      default:
        setIcon(UpdatesIcon); // default icon
    }
  }, [props.IconNumber]);

  return (
    <div className="feature-card">
      <img className="feature-card-icon" src={Icon} />
      <div className="feature-card-text">
        <h3 className="feature-card-heading">{props.Heading}</h3>
        <span className="feature-card-subheading">{props.SubHeading}</span>
      </div>
    </div>
  )
}

export default FeatureCard