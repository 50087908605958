import './OurMission.css';
import React from 'react';

function OurMission() {
    return (
        <div className='about-mission-container'>
            <div className='about-mission-heading'>Our Mission</div>
            <br />
            <div className='about-mission-subheading'>Nuntium aims to become the primary information engine for emerging market research and investment, providing quantitative and honest insights into the most overlooked economies of today.</div>
        </div>
    )
}

export default OurMission
