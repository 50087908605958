import React, { useState } from 'react';
import './ContactSection.css'; // Ensure you update your CSS file with the styles provided later

import ContactSectionInfo from './ContactSectionInfo.js';
import ContactSectionForm from './ContactSectionForm.js';

const ContactSection = () => {
    return (
        <section className="contact-container">
            <ContactSectionInfo />
            <ContactSectionForm />
        </section>
    );
};

export default ContactSection;