import React from 'react';
import './ContactPageSection.css';
import ContactSection from '../landing_page_components/ContactSection';

const ContactPageSection = () => {
    return (
        <div className="contact-us">
            <div className="contact-us-shadow-box ">
                <ContactSection />
            </div>
        </div>

    );
}

export default ContactPageSection;