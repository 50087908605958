// HeroSection.js
import React from 'react';
import './HeroSection.css';
import img from '../assets/HeroImage.png';


function HeroSection() {

    return (
        <div className="hero-container">
            <div>
                <div className="hero-text">Demystify Emerging Markets Using Artificial Intelligence</div>
                {/* <div className="hero-subtext">We use AI to give your evenings back.</div> */}
                <div className="hero-button-container">
                    <div onClick={() => window.open('http://demo1.nuntiumai.com', '_blank', 'noopener,noreferrer')}>
                        <div>Try Demo</div>
                    </div>
                </div>
            </div>
            <img className="hero-image" src={img} alt="hero" />
        </div>
    );
}

export default HeroSection;
