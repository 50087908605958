import React, { useState } from 'react';
import './FooterSection.css';
import { Link } from 'react-router-dom';


// Footer component
const FooterSection = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };


    return (
        <div className="footerContainer">
            <div className="contentContainer">
                <div className="brandContainer">
                    <span className="brandText">nuntium</span>
                    <span className="brandAccent">ai</span>
                </div>
                <div className="navContainer">
                    <Link onClick={scrollToTop} to="/" className="nav-item">Home</Link>
                    <Link onClick={scrollToTop} to="/about" className="nav-item">About</Link>
                    <Link onClick={scrollToTop} to="/case_study" className="nav-item">Case Studies</Link>
                    <Link onClick={scrollToTop} to="/contact" className="nav-item">Contact</Link>
                    <Link onClick={scrollToTop} to='/policy' className="nav-item">Privacy Policy</Link>

                </div>
                <div className="divider"></div>
                <div className="copyright">© 2024 NuntiumAI, All Rights Reserved</div>
            </div>
        </div>
    );
}

export default FooterSection;