import React, { useState } from 'react';
import './ContactSectionInfo.css';
import address from '../assets/Address.svg';
import phone from '../assets/Phone.svg';
import email from '../assets/Email.svg';

const ContactSectionInfo = () => {
    return (
        <div className="contact-info-container">
            <div className="contactTitle">CONTACT US</div>
            <div className="mainTitle"><span>Get in Touch With Us</span></div>
            <div className="description">Have any questions? Want to talk to us? We are here for you! Just reach out to us by filling the form or through one of the methods below:</div>
            <div className="contactInfo">
                <div className="iconContainer">
                    <div className="iconBackground"></div>
                    <img className="icon" src={email} alt="icon" />
                </div>
                <div className="infoContainer">
                    <div className="infoTitle">Our Email</div>
                    <div className="info">foundingteam@nuntiumai.com</div>
                </div>
            </div>
            <div className="contactInfo">
                <div className="iconContainer">
                    <div className="iconBackground"></div>
                    <img className="icon" src={phone} alt="icon" />
                </div>
                <div className="infoContainer">
                    <div className="infoTitle">Our Contact Number</div>
                    <div className="info">(+1) 914-413-5740
                    </div>
                </div>
            </div>
            <div className="contactInfo">
                <div className="iconContainer">
                    <div className="iconBackground"></div>
                    <img className="icon" src={address} alt="icon" />
                </div>
                <div className="infoContainer">
                    <div className="infoTitle">Our Address</div>
                    <div className="info">New York City, United States</div>
                </div>
            </div>
        </div>

    )
};

export default ContactSectionInfo;
