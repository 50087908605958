// TeamCard.js
import React from 'react';
import './TeamCard.css'; // Assuming you will create a separate CSS file for this component
import { FaLinkedinIn, FaEnvelope } from 'react-icons/fa';

const TeamCard = ({ image, name, description, linedInUrl, emailAddress }) => {
    return (
        <div className="teamCard">
            <div className="cardImage">
                <img src={image} alt={name} />
            </div>
            <div className="cardInfo">
                <div className="name">{name}</div>
                <div className="socialMedia">
                    <a href={linedInUrl}><FaLinkedinIn size={20} color="#1678EA" /></a>
                    <a href={`mailto:${emailAddress}`}><FaEnvelope size={20} color="#1678EA" /></a>
                </div>
            </div>
            {/* <p className="description">{description}</p> */}
        </div>
    );
};

export default TeamCard;