import React from 'react';
import './PartnersCarousel.css';
// ArrowButton Component for navigation
const ArrowButton = ({ direction, onClick }) => (
    <button onClick={onClick}>
        {direction === 'left' ? '<' : '>'}
    </button>
);

// CarouselItem Component for each partner logo
const CarouselItem = ({ children }) => (
    <div className="carousel-item">
        {children}
    </div>
);

// PartnersCarousel Main Component
class PartnersCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentIndex: 0, // Current index of the displayed partner
        };
    }

    // Function to navigate to the previous item
    goLeft = () => {
        this.setState(prevState => ({
            currentIndex: prevState.currentIndex > 0 ? prevState.currentIndex - 1 : 0,
        }));
    };

    // Function to navigate to the next item
    goRight = () => {
        this.setState(prevState => ({
            currentIndex: prevState.currentIndex < this.props.partners.length - 1 ? prevState.currentIndex + 1 : prevState.currentIndex,
        }));
    };

    render() {
        // Assuming 'partners' is passed as a prop now, else use the dummy data as fallback
        const partners = this.props.partners || [1, 2, 3, 4, 5]; // Replace with actual partner data
        const { currentIndex } = this.state;

        return (
            <div className="partners-carousel-base">
                <div className="partners-carousel">
                    <h2>Our Partners</h2>
                    <div className="carousel-container">
                        <ArrowButton direction="left" onClick={this.goLeft} />
                        <CarouselItem key={partners[currentIndex]}>
                            {/* Placeholder for partner logo */}
                            <div>Partner {partners[currentIndex]}</div>
                        </CarouselItem>
                        <ArrowButton direction="right" onClick={this.goRight} />
                    </div>
                    <button>Contact Us</button>
                </div>
            </div>
        );
    }
}

export default PartnersCarousel;
