// CaseStudies.js
import React, { useState, useEffect } from 'react';
import './CaseStudies.css';

function CaseStudies({ }) {
    // This should be populated with the data for each case study
    const optionsData = {
        'Poland': {
            research_scope: 'Poland’s Central Bank Rate Policy (April 2024)',
            knowledgeSynthesis: `
            <br/>
            The National Bank of Poland (NBP) maintained its reference rate at 5.75% despite ongoing concerns about inflation and economic uncertainties. This decision was confirmed during meetings of the Monetary Policy Council, with the latest taking place on April 3-4, 2024. Other rates such as the Lombard rate were also kept steady at 6.25%. This continued stance of holding interest rates constant is in alignment with projections for the year, signaling a probability of unchanged rates persisting until the end of 2024. (<a href="https://hlb-poland.global/national-bank-of-poland-interest-rates-in-april-2024/" style="color: darkblue"><b>Monetary Policy Council Meeting</b></a>)
<br/><br/>
Regarding the inflation situation in Poland, the country registered a substantial decrease in its annual inflation rate, dropping to 1.9% in March 2024 from the 2.8% observed in the previous month. This marks a notable deceleration from the 10.9% yearly inflation rate seen in 2023. The easing inflationary pressures align with the stabilization efforts and monetary policy direction of the National Bank of Poland. (<a href="https://economy-finance.ec.europa.eu/economic-surveillance-eu-economies/poland/economic-forecast-poland_en" style="color: darkblue"><b>Economic forecast for Poland</b></a>)
<br/><br/>
Political and operational tensions between the Polish government and NBP governor Adam Glapinski have intensified. The government has laid out charges against Glapinski, aimed at putting him before a state tribunal, with allegations including unauthorized asset purchases and undermining the central bank's independence. If convicted, Glapinski could potentially lose his job and face restrictions on running for any office. This unfolding legal battle and the associated political maneuvers underscore the fragility of central bank independence in Poland amid contested monetary policy directions. (<a href="https://www.reuters.com/world/europe/polish-deputy-pm-says-probe-into-central-bank-chief-is-justified-2024-03-21/" style="color: darkblue"><b>Polish Central Bank Chief's Tribunal</b></a>)
        `,
            baseResponse: `<br/>Over the past year, Poland's central bank rate policy has been focused on managing high inflation while supporting economic stability. The National Bank of Poland (NBP) maintained high interest rates throughout 2023 to combat inflation. The key reference rate was held at 6.75% for a significant portion of the year before being reduced to 6% by the end of the year​ (<a href="https://www.focus-economics.com/countries/poland/news/monetary-policy/central-bank-stands-pat-again-at-final-meeting-of-2023/" style="color: darkblue"><b>Focus Economics</b></a>)​.
            <br/><br/>

            In December 2023, the NBP decided to keep the key reference rate unchanged at 5.75%, following a pattern of stabilizing rates as inflation pressures began to moderate. The bank cited moderating inflation, a stronger Polish zloty, easing supply chain disruptions, and weak domestic activity as reasons for holding the rate steady​ (<a href="https://www.focus-economics.com/countries/poland/news/monetary-policy/central-bank-stands-pat-again-at-final-meeting-of-2023/" style="color: darkblue"><b>Focus Economics</b></a>)​.
            <br/><br/>
            
            Looking ahead, there were expectations in early 2024 that the interest rates might decline further as inflation retreated. The NBP signaled readiness to adjust monetary policy based on economic and inflationary trends, including taking actions to ensure macroeconomic and financial stability​ (<a href="https://www.focus-economics.com/countries/poland/news/monetary-policy/central-bank-stands-pat-again-at-final-meeting-of-2023/" style="color: darkblue"><b>Focus Economics</b></a>)​​. The forecasts suggested that interest rates could remain at elevated levels into 2024, largely influenced by ongoing economic factors and external pressures such as the geopolitical situation in Eastern Europe​ (<a href="https://capital.com/projected-poland-interest-rate-in-5-years-nbp" style="color: darkblue"><b>The Future of Trading</b></a>)​.
            <br/><br/>`
        },
        'India': {
            research_scope: 'India’s ForEx Reserves (April 2024)',
            knowledgeSynthesis: `
            
<br/> 
Between January and April 2024, India's foreign exchange reserves saw significant growth, culminating in a record high. Here's a detailed summary of these developments:
<br/> <br/> 
<b>February 9, 2024</b>: India's forex reserves stood at $642.631 billion.
<br/> <br/> 
<b>March 8, 2024</b>: The reserves further increased, reaching a two-year high of $636.10 billion. This marked the third consecutive week of growth for India's Forex reserves.
<br/> <br/> 
<b>March 22, 2024</b>: Continued growth was observed, with reserves climbing to $642.63 billion.
<br/> <br/> 
<b>March 29, 2024</b>: By the end of March, the reserves had surged to $645.6 billion, setting a new record at that time. Governor Shaktikanta Das of the Reserve Bank of India highlighted this achievement.
<br/> <br/> 
<b>April 5, 2024</b>: The upward trajectory continued, and by early April, the reserves reached an all-time high of $648.56 billion. This milestone was achieved after seven consecutive weeks of growth.
<br/> <br/> 
Throughout the period from January to April 2024, India's foreign exchange reserves increased cumulatively by about $20 billion. The final figure of $648.56 billion on April 5th set a new record for India's forex reserves. The increase during the first week of April included a significant rise in gold reserves by $2.398 billion, along with a $549 million increment in foreign currency assets.
<br/> <br/> 
Sources:
<ol>
<li>
<a href="https://m.economictimes.com/news/economy/indicators/indias-forex-reserves-up-by-2-98-bn-to-hit-fresh-peak-of-648-56-bn/articleshow/109247363.cms" style="color: darkblue"><b>Economic Times</b></a>
</li>
<li>
<a href="https://www.reuters.com/world/india/indias-forex-reserves-rise-seventh-consecutive-week-record-high-2024-04-12/" style="color: darkblue"><b>Reuters</b></a>
</li>
<li>
<a href="https://www.thehindubusinessline.com/markets/forex/indias-forex-reserves-rise-to-an-all-time-high-of-649-billion/article68059023.ece" style="color: darkblue"><b>The Hindu Business Line</b></a>
</li>
</ol>

            `,
            baseResponse: `<br/>As of early April 2024, India's foreign exchange reserves reached a record high of $648.56 billion, marking a significant increase from the previous week's total of $645.58 billion. This peak reflects a substantial growth trajectory from historical levels, with the reserves averaging $287.33 billion since 1998, when they were as low as $29.05 billion. The increase in reserves can provide a robust buffer for the country's balance of payments and help stabilize the Indian rupee against fluctuations in the forex market. (<a href="https://money.usnews.com/investing/news/articles/2024-04-13/china-ev-maker-nio-urges-openness-in-us-speech-amid-tensions" style="color: darkblue"><b>Investing</b></a>)​`
        },

        'China': {
            research_scope: 'Key Players in Chinese EV Manufacturing (April 2024)',
            knowledgeSynthesis: `

            <br/> 
            <b>Nio's Global Outreach</b>:
            <br/><br/>
Nio, a premier Chinese EV maker, saw its founder advocate for global market openness during a rare speech in the United States amidst rising tensions and efforts by U.S. politicians to further restrict China's access to the world's second-largest auto market. The emphasis on openness underlines the Chinese EV industry's aim to penetrate international markets against the backdrop of geopolitical tensions. (<a href="https://money.usnews.com/investing/news/articles/2024-04-13/china-ev-maker-nio-urges-openness-in-us-speech-amid-tensions" style="color: darkblue"><b>US News Money</b></a>)
<br/><br/>

<b>BYD and China's EV Exports</b>:
<br/><br/>
BYD, a leading EV manufacturer, reported exporting over 240,000 cars in 2023, around 8% of its global sales, with plans to export up to 400,000 units in 2024. The export boom highlights the expanding global reach of Chinese EVs. (<a href="https://www.reuters.com/business/autos-transportation/chinas-ev-export-boom-fuels-surge-demand-new-car-carrying-ships-2024-04-10/" style="color: darkblue"><b>Reuters</b></a>)
<br/><br/>

<b>March Sales Surge</b>: 
<br/><br/>
Chinese EV makers witnessed a strong sales performance in March 2024 following a slow start to the year due to the Lunar New Year. This bounce-back demonstrates the resilient consumer demand for EVs in China. Bloomberg
Quarterly Sales Growth: For the first quarter of 2024, EV sales totaled 1.03 million, marking a 14.7% year-on-year increase but also representing the slowest quarterly growth since the second quarter of 2023.  (<a href="https://www.bloomberg.com/news/articles/2024-04-02/chinese-ev-makers-post-strong-showing-in-march-after-slow-start" style="color: darkblue"><b>Bloomberg</b></a>)
<br/><br/>

<b>EV Market Forecasts and Trends</b>:
<br/><br/>
Annual Sales Projection: The sales of China's new-energy vehicles, including EVs and plug-in hybrids, are projected to grow by 22% in 2024, reaching 11 million vehicles. Despite this growth, the pace is slower than in previous years, indicating a potential market maturation or evolving consumer preferences. (<a href="https://restofworld.org/2024/china-ev-industry-slowdown/" style="color: darkblue"><b>Rest of the World</b></a>)

<br/><br/>
<b>Key Players and Stock Performance</b>:
<br/><br/>
<b>Li Auto's Profitability and Performance</b>: 
<br/><br/>
Li Auto, one of China's largest EV producers, showcased more than 30% gains in 2024, becoming the nation's first major EV startup to achieve profitability. This remarkable financial performance exemplifies the significant growth potential within the Chinese EV sector. (<a href="https://www.nasdaq.com/articles/3-discounted-chinese-ev-stocks-to-buy-before-they-rally" style="color: darkblue"><b>Nasdaq</b></a>)
<br/><br/>

<b>Xiaomi's Foray into EVs</b>: 
<br/><br/>
Xiaomi's launch of a new EV led to a market value surge by $4 billion, indicating robust investor interest and the potential for electronics manufacturers to diversify into the automotive sector, further enriching the Chinese EV landscape.  (<a href="https://www.reuters.com/business/autos-transportation/xiaomis-stock-set-open-up-15-after-launch-electric-car-2024-04-02/" style="color: darkblue"><b>Reuters</b></a>)

             `,
            baseResponse: `

            <br/> 
            Some key players in the Chinese electric vehicle (EV) manufacturing industry include BYD Auto, NIO, XPeng, and Li Auto. BYD Auto, one of the largest EV manufacturers, has a significant presence in both passenger and commercial electric vehicles. NIO is known for its innovative battery-swapping technology and luxury electric SUVs. XPeng focuses on integrating advanced technologies like autonomous driving features into its vehicles. Li Auto specializes in electric SUVs that also incorporate range-extender technology to alleviate range anxiety among users. These companies are part of China's rapidly growing EV market.

        `
        }
        // Add more options here
    };
    const defaultOption = 'China';
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const [entityData, setEntityData] = useState(optionsData[selectedOption] || {});
    const [outputToggle, setOutputToggle] = useState(false);

    useEffect(() => {
        setSelectedOption(defaultOption);
        setEntityData(optionsData[defaultOption] || {});
    }, [defaultOption]);

    useEffect(() => {
        const links = document.querySelectorAll('.case-studies-content a');
        links.forEach(link => {
            link.setAttribute('target', '_blank');
            link.setAttribute('rel', 'noopener noreferrer');
        });
    }, [entityData]);

    const handleToggleOption = (option) => {
        setSelectedOption(option);
        setEntityData(optionsData[option] || {});
    };

    const handleOutputToggle = () => {
        setOutputToggle(!outputToggle);
    };

    // Function to determine what to render based on the toggle
    const renderOutput = () => {
        if (outputToggle) {
            return (
                <>
                    <h3 className="inputHeading">Output Using ChatGPT (Paid Version)</h3><br />
                    <p><strong style={{ textDecoration: 'underline' }}>Knowledge Synthesis</strong> <br />
                        <span dangerouslySetInnerHTML={{ __html: entityData.baseResponse }}></span>
                    </p>
                </>
            );
        } else {
            return (
                <>
                    <h3 className="inputHeading">Output Using Nuntium</h3><br />
                    <p><strong style={{ textDecoration: 'underline' }}>Knowledge Synthesis</strong> <br />
                        <span dangerouslySetInnerHTML={{ __html: entityData.knowledgeSynthesis }}></span>
                    </p>
                </>
            );
        }
    };

    return (
        <div className="case-studies-content">
            {/* Row 1: Option buttons */}
            <div className="toggle-list">
                {Object.keys(optionsData).map(option => (
                    <button
                        key={option}
                        onClick={() => handleToggleOption(option)}
                        className={`toggle-option ${selectedOption === option ? 'selected' : ''}`}
                    >
                        {option}
                    </button>
                ))}
            </div>



            {/* Row 4: Inputs on the right and Output on the left */}
            {/* Row 2: Heading and ToggleOption */}
            <div className="case-studies-container">
                <div className="heading-toggle-container">
                    <div className="heading">{selectedOption} Case Study</div>
                    <button onClick={handleOutputToggle} className="toggleButton">
                        {outputToggle ? 'View Nuntium Output' : 'View ChatGPT Output'}
                    </button>
                </div>

                {/* Row 3: Divider line */}
                <hr />

                <div className="case-study-info">

                    <div className="case-study-info-left">
                        <h3 className="inputHeading">Inputs</h3><br />
                        <p><strong>Area of Research:</strong> <br /><br />
                            <span className="entityValue">{entityData.research_scope}</span>
                        </p> <br />
                    </div>
                    <div className="case-study-info-right">
                        {renderOutput()}
                    </div>
                </div>
            </div>
        </div>

    );
}

export default CaseStudies;